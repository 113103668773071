/**
 * 邮箱
 * @param {*} s
 */
export function isEmail (s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}
/**
 * 手机号码
 * @param {*} s
 */
export function isMobile (s) {
  return /^1[3456789]\d{9}$/.test(s)
}
/**
 * 电话号码
 * @param {*} s
 */
export function isPhone (s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}
/* 合法uri */
export function validateURL (textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return urlregex.test(textval)
}

/* 小写字母 */
export function validateLowerCase (str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/* 大写字母 */
export function validateUpperCase (str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/* 大小写字母 */
export function validatAlphabets (str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}
/**
 * 校验 email
 * @param email
 * @returns {boolean}
 */
export function validateEmail (email) {
  const re = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}
/**
 * 判断座机号码是否正确
 */
export function isLandline (phone) {
  const list = []
  let result = true
  let msg = ''
  if (!valDateNull(phone)) {
    if (!isPhone(phone)) {
      msg = '电话格式不正确'
    } else {
      result = false
    }
  } else {
    msg = '电话不能为空'
  }
  list.push(result)
  list.push(msg)
  return list
}

/**
 * 判断手机号码是否正确
 */
export function isValDateMobile (phone) {
  const list = []
  let result = true
  let msg = ''
  // 增加134 减少|1349[0-9]{7}，增加181,增加145，增加17[678]
  if (!valDateNull(phone)) {
    if (phone.length === 11) {
      if (!isMobile(phone)) {
        msg = '手机号码格式不正确'
      } else {
        result = false
      }
    } else {
      msg = '手机号码长度不为11位'
    }
  } else {
    msg = '手机号码不能为空'
  }
  list.push(result)
  list.push(msg)
  return list
}

/**
 * 判断统一信用代码
 */
export function creditCode (Code) {
  var patrn = /^[0-9A-Z]+$/ // 18位校验及大写校验
  if (Code.length !== 18 || patrn.test(Code) === false) {
    return false
  } else {
    var Ancode // 统一社会信用代码的每一个值
    var Ancodevalue // 统一社会信用代码每一个值的权重
    var total = 0
    var weightedfactors = [1, 3, 9, 27, 19, 26, 16, 17, 20, 29, 25, 13, 8, 24, 10, 30, 28] // 加权因子
    var str = '0123456789ABCDEFGHJKLMNPQRTUWXY' // 不用I、O、S、V、Z
    for (var i = 0; i < Code.length - 1; i++) {
      Ancode = Code.substring(i, i + 1)
      Ancodevalue = str.indexOf(Ancode)
      total = total + Ancodevalue * weightedfactors[i] // 权重与加权因子相乘之和
    }
    var logiccheckcode = 31 - (total % 31)
    if (logiccheckcode === 31) {
      logiccheckcode = 0
    }
    var Str = '0,1,2,3,4,5,6,7,8,9,A,B,C,D,E,F,G,H,J,K,L,M,N,P,Q,R,T,U,W,X,Y'
    var ArrayStr = Str.split(',')
    logiccheckcode = ArrayStr[logiccheckcode]
    var checkcode = Code.substring(17, 18)
    if (logiccheckcode !== checkcode) {
      return false
    }
    return true
  }
}

/**
 * 判断身份证号码
 */
// 身份证检验
export const cardIdCheck = id => {
  // 1 "验证通过!", 0 //校验不通过
  var format = /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/
  // 号码规则校验
  if (!format.test(id)) {
    return { status: 0, msg: '身份证号码不合规' }
  }
  // 区位码校验
  // 出生年月日校验 前正则限制起始年份为1900
  var year = id.substr(6, 4), // 身份证年
    month = id.substr(10, 2), // 身份证月
    date = id.substr(12, 2), // 身份证日
    time = Date.parse(month + '-' + date + '-' + year), // 身份证日期时间戳date
    nowTime = Date.parse(new Date()), // 当前时间戳
    dates = new Date(year, month, 0).getDate() // 身份证当月天数
  if (time > nowTime || date > dates) {
    return { status: 0, msg: '出生日期不合规' }
  }
  // 校验码判断
  var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2) // 系数
  var b = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2') // 校验码对照表
  var idArray = id.split('')
  var sum = 0
  for (var k = 0; k < 17; k++) {
    sum += parseInt(idArray[k]) * parseInt(c[k])
  }
  if (idArray[17].toUpperCase() !== b[sum % 11].toUpperCase()) {
    return { status: 0, msg: '身份证校验码不合规' }
  }
  return { status: 1, msg: '校验通过' }
}

/**
 * 判断是否为空
 */
export function valDateNull (val) {
  if (typeof val === 'boolean') {
    return false
  }
  if (typeof val === 'number') {
    return false
  }
  if (val instanceof Array) {
    if (val.length === 0) return true
  } else if (val instanceof Object) {
    if (JSON.stringify(val) === '{}') return true
  } else {
    if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '') return true
    return false
  }
  return false
}
// 手机号验证
export function isvalidPhone (str) {
  const reg = /^1[3|4|5|7|8][0-9]\d{8}$/
  return reg.test(str)
}

// 验证密码   密码，以字母开头，长度在6~18之间，只能包含字母、数字和下划线
export function isvalidPass (str) {
  const reg = /^[a-zA-Z]\w{6,18}$/
  return reg.test(str)
}

//   验证用户名  用户名要求 数字、字母、下划线的组合，其中数字和字母必须同时存在*
export function isvalidUsername (str) {
  const reg = /^(?![^A-Za-z]+$)(?![^0-9]+$)[0-9A-Za-z_]{4,15}$/
  return reg.test(str)
}

// 判断是否为正整数
export function isPositiveNum (s) { // 是否为正整数
  const reg = /^[0-9]\d*$/
  return reg.test(s)
}

// 判断是否为整数且可保留两位
export function isFixedNum (num) {
  const reg = /^\d+(.\d{1,2})?$/
  return reg.test(num)
}

// 验证是否整数,非必填
export function isIntegerNotMust (rule, value, callback) {
  if (!value) {
    callback()
  }
  setTimeout(() => {
    if (!Number(value)) {
      callback(new Error('请输入正整数'))
    } else {
      const re = /^[0-9]*[1-9][0-9]*$/
      const rsCheck = re.test(value)
      if (!rsCheck) {
        callback(new Error('请输入正整数'))
      } else {
        callback()
      }
    }
  }, 1000)
}
// 密码校验
export const validatePsdReg = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请输入密码'))
  }
  if (!/^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){6,20}$/.test(value)) {
    callback(new Error('请输入6-20位英文字母、数字或者符号（除空格），且字母、数字和标点符号至少包含两种'))
  } else {
    callback()
  }
}

// 时间 2021-05-10
export function getTodayDate () {
  var yy = new Date().getFullYear()
  var mm = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
  var dd = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
  return yy + '-' + mm + '-' + dd
}

/**
 * 根据当前日期获取其前七天的日期
 * @param month
 * @return
 */
export function getRecentDay () {
  var arr = []
  for (let i = 6; i >= 0; i--) {
    var temp = getDay(-i)
    arr.push(temp)
  }
  return arr[0]
}
function getDay (day) {
  var today = new Date()
  var targetDay = today.getTime() + 1000 * 60 * 60 * 24 * day
  today.setTime(targetDay)
  var tYear = today.getFullYear()
  var tMonth = today.getMonth()
  var tDate = today.getDate()
  tMonth = doHandleMonth(tMonth + 1)
  tDate = doHandleMonth(tDate)
  return tYear + '-' + tMonth + '-' + tDate
}
function doHandleMonth (month) {
  let m = month
  if (month.toString().length === 1) {
    m = '0' + month
  }
  return m
}

// 2020-05-10转换5月10号
export function formats (t) {
  var m = t.substring(5, 7)
  var mm = m < 10 ? m.substring(1) : m
  var d = t.substring(8, 10)
  var dd = d < 10 ? d.substring(1) : d
  return mm + '月' + dd + '号'
}

// 获取当前的时间
export function nowTime () {
  var _date = new Date()
  var y = _date.getFullYear()
  var m = zeroChange(_date.getMonth() + 1)
  var d = zeroChange(_date.getDate())
  var h = zeroChange(_date.getHours())
  var mt = zeroChange(_date.getMinutes())
  var s = zeroChange(_date.getSeconds())
  return y + '-' + m + '-' + d + ' ' + h + ':' + mt + ':' + s
}
export function zeroChange (zero) {
  return zero < 10 ? '0' + zero : zero
}

// 转换当前时间
export function getNowTime (t) {
  const _date = new Date(t)
  const y = _date.getFullYear()
  const m = zeroChange(_date.getMonth() + 1)
  const d = zeroChange(_date.getDate())
  const h = zeroChange(_date.getHours())
  const mt = zeroChange(_date.getMinutes())
  const s = zeroChange(_date.getSeconds())
  return y + '-' + m + '-' + d + ' ' + h + ':' + mt + ':' + s
}
