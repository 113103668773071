<template>
<!-- 员工管理 -->
  <div class="whiteBg">
    <template v-if="roleBtn[0].power">
      <div class="el-icon-plus settingAdd"  @click="handleAdd"><span> 新增</span></div>
    </template>
    <div class="flexAC" style="margin: 16px 0px;">
      <el-input v-model="sname"  placeholder="请输入账号/手机号/账号名称" class="inputSearch"></el-input>
      <el-select v-model="staffWord" filterable placeholder="全部门店" class="retSelect"></el-select>
      <el-select v-model="roleid" filterable placeholder="角色" clearable  class="retSelect">
        <el-option v-for="(item) in roleList"
          :key="item.roleid"
          :label="item.rolename"
          :value="item.roleid">
        </el-option>
      </el-select>
      <!-- <el-select  v-model="sjobs" filterable placeholder="岗位" clearable  class="retSelect">
        <el-option v-for="(item) in sjobsList"
          :key="item.sysid"
          :label="item.sysvalue"
          :value="item.sysid">
        </el-option>
      </el-select> -->
      <el-select v-model="state" filterable placeholder="状态" clearable  class="retSelect">
        <el-option
          v-for="(item,index) in stateList"
          :key="index"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" class="btnSearch"  @click="handleSearch">搜索</el-button>
      <el-button class="btnReset"  @click="handleReset">重置</el-button>
    </div>
    <div class="table_list">
      <el-table
        :data="staffList"
        :header-cell-style="{background:'#EBF2FE'}">
        <el-table-column
          label="员工编号"
          prop="staffcode">
        </el-table-column>
        <el-table-column
          label="姓名"
          prop="sname">
        </el-table-column>
        <el-table-column
          label="手机号"
          prop="sphone">
        </el-table-column>
        <el-table-column
          label="性别"
          prop="ssex">
          <template slot-scope="scope">
            <span v-if="scope.row.ssex == 1" >男</span>
            <span v-if="scope.row.ssex != 1" >女</span>
          </template>
        </el-table-column>
        <el-table-column
          label="部门"
          prop="depname"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          label="所属角色"
          prop="rolename">
        </el-table-column>
        <el-table-column
          label="状态"
          prop="state">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1" >启用</span>
            <span v-if="scope.row.state == 0" >停用</span>
            <span v-if="scope.row.state == ''" ></span>
          </template>
        </el-table-column>
        <el-table-column
          label="账号端口"
          prop="sport">
        </el-table-column>
        <el-table-column
          label="操作"
          width="200">
          <template slot-scope="scope">
            <template v-if="roleBtn[2].power">
              <el-button type="text" style="color:#3C84EF" size="small" @click="handEdit(scope.row)">编辑</el-button>
            </template>
            <template v-if="roleBtn[4].power">
              <el-button type="text" style="color:#F44C8E" size="small" v-if="scope.row.state === 1" @click="handleStop(scope.row)">停用</el-button>
              <el-button type="text" style="color:#44B557" size="small" v-if="scope.row.state !== 1" @click="handleStop(scope.row)">启用</el-button>
            </template>
            <template v-if="roleBtn[3].power">
              <el-button type="text" style="color:#3C84EF" size="small" @click="handPassword(scope.row)">重置密码</el-button>
            </template>
            <template v-if="roleBtn[1].power">
              <el-button type="text" style="color:#F44C8E" size="small" @click="handDelete(scope.row)">删除</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="pageChange"
      :current-page="page"
      :page-sizes="[10]"
      :page-size="size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      class="settingPage">
    </el-pagination>

    <!-- 新增弹窗 -->
    <el-dialog title="新增" :visible.sync="addFromShow" width="930px" center class="gyDialog" :close-on-click-modal="false" :before-close="addFromCancel">
      <el-form :model="addfrom" :rules="formRules" ref="addFrom">
        <div class="flexCB foItem">
          <el-form-item label="员工姓名" class="foFSet" prop="sname">
            <el-input v-model="addfrom.sname"  placeholder="请输入员工姓名"></el-input>
          </el-form-item>
          <el-form-item label="手机号码" class="foFSet" prop="sphone">
            <el-input v-model="addfrom.sphone"  placeholder="请输入手机号码"></el-input>
          </el-form-item>
        </div>
        <div class="flexCB foItem">
          <el-form-item label="性别"  prop="ssex">
            <el-radio-group v-model="addfrom.ssex" class="foFSet">
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="岗位" class="foFSet" prop="sjobs">
            <el-input v-model="addfrom.sjobs" clearable   placeholder="请输入岗位"></el-input>
          </el-form-item>
        </div>
        <div class="flexCB foItem">
          <el-form-item label="部门" prop="depid">
            <el-select v-model="addfrom.depid" clearable   placeholder="请选择部门" class="foFSet">
              <el-option v-for="(item) in depeList"
                :key="item.depid"
                :label="item.depname"
                :value="item.depid">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="角色"  prop="roleid">
            <el-select v-model="addfrom.roleid" clearable   placeholder="请选择角色" class="foFSet">
              <el-option v-for="(item) in roleList"
                :key="item.roleid"
                :label="item.rolename"
                :value="item.roleid">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="addFromCancel">取消</div>
        <div class="settingConfirm" @click="AddFromConfirm">确定</div>
      </div>
    </el-dialog>

    <!-- 编辑弹窗 -->
    <el-dialog title="编辑" :visible.sync="editFromShow" width="930px" center class="gyDialog" :close-on-click-modal="false" :before-close="editFromCancel">
      <el-form :model="editfrom" :rules="editformRules" ref="editFrom">
        <div class="flexCB">
          <div class="editLabel">
            <label class="from_label">员工编号</label>
            <p class="udf">{{ editfrom.staffcode }}</p>
          </div>
          <div class="editLabel">
            <label class="from_label">创建时间</label>
            <p class="udf">{{ editfrom.creatime }}</p>
          </div>
        </div>
        <div class="flexCB foItem">
          <div class="editLabel">
            <label class="from_label">员工状态</label>
            <p class="udf">
              <span  v-if="editfrom.state === 1">启用</span>
              <span  v-if="editfrom.state === 0">停用</span>
            </p>
          </div>
          <el-form-item label="员工姓名" class="foFSet" prop="sname">
            <el-input v-model="editfrom.sname"  placeholder="请输入员工姓名"></el-input>
          </el-form-item>
        </div>
        <div class="flexCB foItem">
          <el-form-item label="手机号码" class="foFSet" prop="sphone">
            <el-input v-model="editfrom.sphone"  placeholder="请输入手机号码"></el-input>
          </el-form-item>
          <el-form-item label="性别"  prop="ssex">
            <el-radio-group v-model="editfrom.ssex" class="foFSet">
              <el-radio :label= "1">男</el-radio>
              <el-radio :label= "2">女</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div class="flexCB foItem">
          <el-form-item label="岗位" prop="sjobs">
            <el-input v-model="editfrom.sjobs"  placeholder="请输入岗位" class="foFSet"></el-input>
            <!-- <el-select v-model="editfrom.sjobs" clearable  placeholder="请选择岗位" class="foFSet">
              <el-option v-for="(item) in sjobsList"
                :key="item.sysid"
                :label="item.sysvalue"
                :value="item.sysid">
              </el-option>
            </el-select> -->
          </el-form-item>
          <el-form-item label="部门" prop="depid">
            <el-select v-model="editfrom.depid"  clearable  placeholder="请选择部门" class="foFSet">
              <el-option v-for="(item) in depeList"
                :key="item.depid"
                :label="item.depname"
                :value="item.depid">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flexCB foItem">
          <el-form-item label="角色" prop="roleid">
            <el-select v-model="editfrom.roleid"  clearable  placeholder="请选择角色" class="foFSet">
              <el-option v-for="(item) in roleList"
                :key="item.roleid"
                :label="item.rolename"
                :value="item.roleid">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="editFromCancel">取消</div>
        <div class="settingConfirm" @click="editFromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { staffGet, staffAdd, staffUpdate, staffDelete, staffEnable, staffPwdReset } from '@/api/staff.js'
import { roleDropGet } from '@/api/role.js'
// import { sysGet } from '@/api/system.js'
import { departAllDown } from '@/api/department.js'
import { isValDateMobile } from '@/utils/validate.js'
export default {
  props: ['roleBtn'],
  data () {
    // 手机号码校验
    const checkPhone = (rule, value, callback) => {
      if (value === '') {
        return callback(new Error('请输入单位电话'))
      }
      if (isValDateMobile(value)[0]) {
        callback(new Error(isValDateMobile(value)[1]))
      } else {
        callback()
      }
    }
    return {
      sname: '',
      staffWord: '',
      roleid: '',
      roleList: [],
      sjobs: '',
      sjobsList: [],
      state: '',
      stateList: [{ label: '全部状态', value: '' }, { label: '启用', value: 1 }, { label: '停用', value: 0 }],
      page: 1,
      size: 10,
      total: 0,
      staffList: [],
      addFromShow: false,
      addfrom: {
        sname: '',
        roleid: '',
        sjobs: '',
        ssex: '2',
        sphone: '',
        depname: ''
      },
      formRules: {
        sname: [
          { required: true, trigger: 'blur', message: '员工姓名不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        sphone: [
          { required: true, validator: checkPhone, trigger: 'blur' }
        ],
        ssex: [
          { required: true, trigger: 'blur', message: '请选择性别' }
        ],
        sjobs: [
          { required: true, trigger: 'blur', message: '岗位名称不能为空' }
        ]
      },
      depeList: [],
      editFromShow: false,
      editfrom: {},
      editformRules: {
        sname: [
          { required: true, trigger: 'blur', message: '员工姓名不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        sphone: [
          { required: true, validator: checkPhone, trigger: 'blur' }
        ],
        ssex: [
          { required: true, trigger: 'blur', message: '请选择性别' }
        ],
        sjobs: [
          { required: true, trigger: 'blur', message: '岗位名称不能为空' }
        ]
      },

    }
  },
  created () {
    this.init()
    this.getInit()
  },
  methods: {
    getInit () {
      // 角色下拉
      roleDropGet().then(res => {
        if (res.status === 1) {
          this.roleList = res.data.data
        } else {
          this.roleList = []
        }
      })

      // 岗位下拉
      // sysGet({ systype: 'job' }).then(res => {
      //   if (res.status === 1) {
      //     this.sjobsList = res.data.data
      //   } else {
      //     this.sjobsList = []
      //   }
      // })

      // 部门下拉
      departAllDown().then(res => {
        if (res.status === 1) {
          this.depeList = res.data.data
        } else {
          this.depeList = []
        }
      })
    },
    init () {
      const params = {
        sname: this.sname,
        roleid: this.roleid,
        // sjobs: this.sjobs,
        state: this.state,
        page_index: this.page,
        page_size: this.size
      }
      staffGet(params).then(res => {
        if (res.status === 1) {
          this.staffList = res.data.data
          this.total = res.data.recordcount
        } else {
          this.staffList = []
          this.total = 0
        }
      })
    },
    // 新增
    handleAdd () {
      this.addFromShow = true
    },
    AddFromConfirm () {
      this.$refs.addFrom.validate((valid) => {
        if (!valid) return
        const params = {
          sname: this.addfrom.sname,
          sphone: this.addfrom.sphone,
          ssex: this.addfrom.ssex,
          sjobs: this.addfrom.sjobs,
          roleid: this.addfrom.roleid,
          depid: this.addfrom.depid
        }
        staffAdd(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.addFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    addFromCancel () {
      this.addFromShow = false
      this.$refs.addFrom.resetFields()
    },
    // 编辑
    handEdit (row) {
      this.editFromShow = true
      console.log(row)
      this.getInit()
      this.editfrom = { ...row }
    },
    editFromConfirm () {
      this.$refs.editFrom.validate((valid) => {
        if (!valid) return
        const params = {
          staffid: this.editfrom.staffid,
          sname: this.editfrom.sname,
          sphone: this.editfrom.sphone,
          roleid: this.editfrom.roleid,
          sjobs: this.editfrom.sjobs,
          state: this.editfrom.state,
          ssex: this.editfrom.ssex,
          depid: this.editfrom.depid
        }
        staffUpdate(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.editFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    editFromCancel () {
      this.editFromShow = false
      this.$refs.editFrom.resetFields()
    },
    // 重置密码
    handPassword (row) {
      this.$confirm('是否重置当前员工的密码', '重置密码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        staffPwdReset({ staffid: row.staffid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 删除
    handDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        staffDelete({ staffid: row.staffid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // 停用启用
    handleStop (row) {
      this.$confirm('停用之后该账号将无法登录，是否停用?', '停用', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        staffEnable({ staffid: row.staffid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    handleSearch () {
      this.page = 1
      this.init()
    },
    handleReset () {
      this.page = 1
      this.sname = ''
      this.roleid = ''
      // this.sjobs = ''
      this.state = ''
      this.init()
    },
    pageChange (page) {
      this.page = page
      this.init()
    }
  }
}
</script>

<style scoped>
.foFSet,.editLabel {
  width: 400px;
}
.editLabel {
  margin-bottom: 22px;
}
.editLabel .el-form-item__label{
  width: 100%;
  text-align: left;
}
.udf {
  background: #F7F9FB;
  height: 40px;
  border-radius: 4px;
  line-height: 40px;
  padding-left: 15px;
}
</style>
